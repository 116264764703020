<template>
    <select :name="action.name" :title="action.label" :class="action.class" :style="action.style" :disabled="disabled"
            v-model="action.selected" v-on:change="onSelectAction">
        <template v-for="(option,index) in action.options">
            <option :key="action.name + '-' + index" :value="option.value" :disabled="option.disabled">
                {{ option.text }}
            </option>
        </template>
    </select>
</template>

<script>
export default {
    name: "SelectAction",
    props: {
        action: {type: Object, default: null},
        disabled: {type: Boolean, default: false},
        forwardEvents: {type: Boolean, default: false},
    },
    methods: {
        onSelectAction() {
            let option = this.action.options.find(option => option.value === this.action.selected);
            let event = (this.action.event ? this.action.event : (option.event ? option.event : this.action.name));
            if (this.forwardEvents) {
                this.$emit('forward-event', {event: event, data: option});
            } else {
                this.$emit(event, option);
            }
        }
    }
}
</script>

<style scoped>
</style>
